define(['app'], function (app) {

  var simpleResponsiveHeader = function() {
    var self = this;
    self.app = app;

    var _config = {
      menuButtonSelector: '[data-js-element=menuButton]',
      modClassAttribute: 'data-modifier-class',
      breakpointAttribute: 'data-header-breakpoint',
      breakpointSelector: '[data-header-breakpoint]',
      publishChannel: 'simpleResponsiveHeader/mobileNavMenuStatus'
    };

    var _init = function(element) {
      self.element = element;
      self.menuButton = self.element.querySelector(self.config.menuButtonSelector);
      self.modClass = self.menuButton.getAttribute(self.config.modClassAttribute);

      /* In order to override set a data element at the top of main-page.jsp (see hale for example) */
      self.headerBreakpoint = 900;
      if (document.querySelector(self.config.breakpointSelector)) {
        self.headerBreakpoint = parseInt(document.querySelector(self.config.breakpointSelector)
          .getAttribute(self.config.breakpointAttribute), 10);
      }

      self.bind();
    };

    var _bind = function() {
      self.menuButton.addEventListener('click', self.toggle);
      window.addEventListener('resize', self.resizeEvent);
    };

    var _toggle = function() {
      if (self.modClass) {
        if (self.app.element.hasClass(self.modClass, self.menuButton)) {
          self.app.element.removeClass(self.modClass, self.menuButton);
          app.publish(self.config.publishChannel, 'closed');
        } else {
          self.app.element.addClass(self.modClass, self.menuButton);
          app.publish(self.config.publishChannel, 'open');
        }
      }
    };

    var _resizeEvent = function() {
      if (self.getWindowWidth() > self.headerBreakpoint) {
        self.app.element.removeClass(self.modClass, self.menuButton);
        app.publish(self.config.publishChannel, 'closed');
      }
    };

    var _getWindowWidth = function() {
      return window.innerWidth;
    };

    self.config = _config;
    self.init = _init;
    self.bind = _bind;
    self.toggle = _toggle;
    self.resizeEvent = _resizeEvent;
    self.getWindowWidth = _getWindowWidth;
  };

  return simpleResponsiveHeader;
});